import { createPublicClient, defineChain, http } from 'viem'
import { publicActionsL1, publicActionsL2 } from 'viem/op-stack'
import { CHAINS } from 'constants/network'

export const publicClientL1 = createPublicClient({
  chain: defineChain({ ...CHAINS.l1 }),
  transport: http(),
}).extend(publicActionsL1())

export const publicClientL2 = createPublicClient({
  chain: defineChain({ ...CHAINS.zircuit }),
  transport: http(),
}).extend(publicActionsL2())
