import { waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import { BRIDGE_MIN_GAS_LIMIT_ETH } from 'constants/network'
import { wagmiConfig } from 'lib/wagmi'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'

export const depositETH = async ({
  amount,
  account,
  setPendingTransactionHash,
}: {
  amount: string
  account: Address
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
}) => {
  const { L1StandardBridge, L1StandardBridgeProxy } = getSmartContracts()

  const depositETHHash = await writeContract(wagmiConfig, {
    address: L1StandardBridgeProxy.address,
    abi: L1StandardBridge.abi,
    functionName: 'bridgeETHTo',
    args: [account, BRIDGE_MIN_GAS_LIMIT_ETH, '0x'], // TODO: User uuid as extra data
    value: ethers.parseEther(amount),
  })

  setPendingTransactionHash(depositETHHash)

  const tx = await waitForTransactionReceipt(wagmiConfig, { hash: depositETHHash })

  return tx
}
