import { waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import { BRIDGE_MIN_GAS_LIMIT_ETH } from 'constants/network'
import { wagmiConfig } from 'lib/wagmi'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'

export const withdrawETH = async ({
  amount,
  account,
  setPendingTransactionHash,
}: {
  amount: string
  account: Address
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
}) => {
  const { L1StandardBridge, L2StandardBridge } = getSmartContracts()

  const withdrawETHHash = await writeContract(wagmiConfig, {
    address: L2StandardBridge.address,
    // Hack: L1 and L2 bridge have almost the same abi. Since we don't have the abi for L2 contracts, just use that one.
    // Check "getL2StandardBridge": https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/deployments.ts
    abi: L1StandardBridge.abi,
    functionName: 'bridgeETHTo',
    args: [account, BRIDGE_MIN_GAS_LIMIT_ETH, '0x'], // TODO: User uuid as extra data
    value: ethers.parseEther(amount),
  })

  setPendingTransactionHash(withdrawETHHash)

  const tx = await waitForTransactionReceipt(wagmiConfig, { hash: withdrawETHHash })

  return tx
}
