import type { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import type { waitForTransactionReceipt } from '@wagmi/core'
import { useEffect } from 'react'
import { L1_LAYER_IDS } from 'constants/network'
import type {
  GetRecentTransactions200 as RecentTransactions,
  GetTransactions200 as Transactions,
  RecentTransactionsListItem as TxItem,
} from 'generated/reactQueryClient'
import type { ErrorType } from 'utils/axios'

type Props = {
  transactions: Transactions | RecentTransactions | undefined
  refetch: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<Transactions | RecentTransactions, ErrorType<unknown>>>
  bridgedTransaction: Awaited<ReturnType<typeof waitForTransactionReceipt>> | null
  setInternalBridgedTransactionData: React.Dispatch<React.SetStateAction<TxItem | null>>
  from: { id: number; label: string }
}

export const useHandleCurrentTransaction = ({
  transactions,
  refetch,
  bridgedTransaction,
  setInternalBridgedTransactionData,
  from,
}: Props): void => {
  useEffect(() => {
    const handleCurrentTransaction = async () => {
      setInternalBridgedTransactionData(null)

      if (!bridgedTransaction) {
        return
      }

      await refetch()

      if (!transactions?.data) {
        return
      }

      if (L1_LAYER_IDS.includes(from.id)) {
        const transaction = transactions.data.find(
          (tx) => tx.l1TxHash === bridgedTransaction.transactionHash,
        )

        if (!transaction) {
          return
        }

        setInternalBridgedTransactionData(transaction)
      } else {
        const transaction = transactions.data.find(
          (tx) => tx.l2TxHash === bridgedTransaction.transactionHash,
        )

        if (!transaction) {
          return
        }

        setInternalBridgedTransactionData(transaction)
      }
    }

    void handleCurrentTransaction()
  }, [refetch, bridgedTransaction, transactions, from.id, setInternalBridgedTransactionData])
}
