import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import type { Erc20Token } from 'apollo/generated/graphqlClient'
import { BRIDGE_MIN_GAS_LIMIT_ERC20 } from 'constants/network'
import { wagmiConfig } from 'lib/wagmi'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'

export const withdrawERC20 = async ({
  amount,
  selectedAsset,
  account,
  setPendingTransactionHash,
}: {
  amount: string
  selectedAsset: Erc20Token
  account: Address
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
}) => {
  const { L1StandardBridge, L2StandardBridge } = getSmartContracts()

  const bigIntAmount = BigInt(ethers.parseUnits(amount, selectedAsset.decimals_l2).toString())

  // Hack: L1 and L2 bridge have almost the same abi. Since we don't have the abi for L2 contracts, just use that one.
  // Check "getL2StandardBridge": https://github.com/zircuit/zkr-monorepo/blob/b3cca940f7aebe2ce578173ae0c339506f2f6613/packages/contracts-bedrock/qs-e2e-test/src/deployments.ts.
  const abi = JSON.parse(selectedAsset.abi_l1)

  // @ts-expect-error - Missing type
  // eslint-disable-next-line
  const isMintable = abi.find((method) => 'name' in method && method.name === 'mint')

  if (!isMintable) {
    await readContract(wagmiConfig, {
      address: selectedAsset.contract_address_l2 as Address,
      abi,
      functionName: 'allowance',
      args: [account, L2StandardBridge.address],
    })

    const hash = await writeContract(wagmiConfig, {
      address: selectedAsset.contract_address_l2 as Address,
      abi,
      functionName: 'approve',
      args: [L2StandardBridge.address, bigIntAmount],
    })

    await waitForTransactionReceipt(wagmiConfig, { hash })
  }

  const withdrawERC20Hash = await writeContract(wagmiConfig, {
    address: L2StandardBridge.address,
    // Hack: L1 and L2 bridge have almost the same abi. Since we don't have the abi for L2 contracts, just use that one.
    // Check "getL2StandardBridge": https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/deployments.ts
    abi: L1StandardBridge.abi,
    functionName: 'bridgeERC20To',
    args: [
      selectedAsset.contract_address_l2 as Address,
      selectedAsset.contract_address_l1 as Address,
      account,
      bigIntAmount,
      BRIDGE_MIN_GAS_LIMIT_ERC20,
      '0x',
    ],
  })

  setPendingTransactionHash(withdrawERC20Hash)

  const tx = await waitForTransactionReceipt(wagmiConfig, { hash: withdrawERC20Hash })

  return tx
}
