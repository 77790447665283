import type { FlexProps } from '@chakra-ui/react'
import { Flex, Icon, IconButton, Text } from '@chakra-ui/react'
import React from 'react'
import { RiAlertLine, RiCloseFill, RiCloseLine } from 'react-icons/ri'
import { useThemeColors } from 'hooks/useThemeColors'

const VARIANTS = {
  error: 'error',
  warning: 'warning',
}

type AlertProps = {
  isOpen?: boolean
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
  variant: keyof typeof VARIANTS
  hasCloseButton?: boolean
} & FlexProps

export const Alert = ({
  isOpen = true,
  setIsOpen = () => {},
  children,
  variant,
  hasCloseButton = false,
  ...props
}: AlertProps) => {
  const COLORS = useThemeColors()

  const APPEARANCE = {
    [VARIANTS.error]: {
      bgColor: COLORS.errorBg,
      borderColor: COLORS.errorBg2,
      color: COLORS.error,
      icon: RiCloseLine,
    },
    [VARIANTS.warning]: {
      color: COLORS.critical,
      borderColor: COLORS.criticalBg2,
      bgColor: COLORS.criticalBg,
      icon: RiAlertLine,
    },
  }

  const { icon, ...styles } = APPEARANCE[variant]

  return isOpen ? (
    <Flex
      p={2.5}
      border="1px solid"
      borderRadius={8}
      alignItems="flex-start"
      justifyContent="space-between"
      gap={2}
      {...styles}
      {...props}
    >
      <Flex>
        <Icon as={icon} color="inherit" style={{ flexShrink: 0 }} />
        <Text ml={2} variant="caption2regular" color="inherit" maxW={475}>
          {children}
        </Text>
      </Flex>
      {hasCloseButton && (
        <IconButton
          aria-label="close button"
          icon={<RiCloseFill />}
          onClick={() => {
            setIsOpen(false)
          }}
          w={4}
          h={4}
          variant="unstyled"
        />
      )}
    </Flex>
  ) : null
}
